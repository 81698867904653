import { Avatar, Grid, Spacer, Text, Tooltip, useTheme } from '@nextui-org/react';
import { AirplaneIcon, GithubIcon, InstagramIcon, LinkedInIcon, MailIcon } from 'components/images';
import PageContainer from 'components/pageContainer';
import Image from 'next/image';
import Link from 'next/link';

('use strict');

interface IIconLink {
  Icon: JSX.Element;
  tooltip: string;
  destination: string;
}

const IconLink = (props: IIconLink) => {
  return (
    <Tooltip content={props.tooltip}>
      {props.destination.startsWith('http') ? (
        <a
          aria-label={props.tooltip}
          href={props.destination}
          target="_blank"
          rel="noreferrer noopener"
          style={{ display: 'flex' }}
        >
          {props.Icon}
        </a>
      ) : (
        <Link aria-label={props.tooltip} href={props.destination}>
          {props.Icon}
        </Link>
      )}
    </Tooltip>
  );
};

const Home = () => {
  const { type } = useTheme(); // theme, isDark

  return (
    <PageContainer css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {/* <Container display="flex" css={{ flexDirection: 'column' }}> */}
      <Image
        // priority
        src={'/me.jpg'}
        width={120}
        height={120}
        style={{ borderRadius: '50%' }}
        alt={'Rikhil Raithatha'}
      />

      <Text
        h1
        css={{
          marginBottom: -10,
          textAlign: 'center',
        }}
      >
        Rikhil Raithatha
      </Text>
      <Spacer y={0.5} />
      <Text
        h4
        css={{
          m: 0,
          textAlign: 'center',
        }}
      >
        Bansuri player (flautist) · Software engineer
      </Text>
      <Spacer y={0.5} />
      <div
        style={{
          borderBottom: '1px solid gray',
          width: '100%',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
      />
      <Spacer y={0.5} />
      <Grid.Container gap={1} css={{ alignSelf: 'center', width: '100%' }}>
        <Grid xs={2.4} justify="center">
          <IconLink
            Icon={<AirplaneIcon size={40} isDark={type === 'dark'} />}
            tooltip="Projects"
            destination="/projects"
          />
        </Grid>
        <Grid xs={2.4} justify="center">
          <IconLink
            Icon={<GithubIcon size={40} isDark={type === 'dark'} />}
            tooltip="Github"
            destination="https://github.com/rikhilrai"
          />
        </Grid>
        <Grid xs={2.4} justify="center">
          <IconLink
            Icon={<LinkedInIcon size={40} isDark={type === 'dark'} />}
            tooltip="LinkedIn"
            destination="https://www.linkedin.com/in/rikhilrai/"
          />
        </Grid>
        {/* <Grid xs={2.4} justify="center">
          <IconLink
            Icon={<HumanIcon size={40} isDark={type === 'dark'} />}
            tooltip="My brother: Akhil Raithatha"
            destination="https://akhilraithatha.co.uk"
          />
        </Grid> */}
        <Grid xs={2.4} justify="center">
          <IconLink
            Icon={<InstagramIcon size={40} isDark={type === 'dark'} />}
            tooltip="Instagram"
            destination="https://www.instagram.com/bansuriboy/"
          />
        </Grid>
        <Grid xs={2.4} justify="center">
          <IconLink Icon={<MailIcon size={40} isDark={type === 'dark'} />} tooltip="Contact" destination="/contact" />
        </Grid>
      </Grid.Container>
      {/* </Container> */}
    </PageContainer>
  );
};

export default Home;
